import { useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";
import { send_data } from "./lib";



function User_center(props)
{
    const cu_message_page=props.cu_message_page;       

    function My_profile()
    {
        const up_img=useRef(null);
        const [user_avt,set_user_avt]=useState(null);
        const [user_nick,set_user_nick]=useState(null);
        
        //上传图片
        const handleFileInputChange = async (event) => {
        const file = event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);                
        reader.onload = function(e) 
        {
            if (reader.readyState === 2) 
            {
                set_user_avt(e.target.result);
                send_data({"api":"up_img","img":e.target.result,"file_type":file.type,"mold":"avt"},(e)=>{
                })
            }
        }    
        };

        useEffect(()=>{
            send_data({"api":"get_user_profile"},(e)=>{
                if(e.avt!=null)
                set_user_avt(e.avt);
                if(e.nick!=null)
                set_user_nick(e.nick);
            })
        },[])


        function save_nick()
        {
            send_data({"api":"save_nick","nick":user_nick},(e)=>{
               if(e.status!='success')
               {
                    alert(e.status);
                    return;
               }
               alert("成功");
            })
        }

        return(
            <div className="my_profile">
                <input ref={up_img} onChange={handleFileInputChange} className="nodisplay" type="file" accept=".jpg,.png,.bmp,.jpeg" ></input>
                <div className="change_avt">
                    <span className="norm_str">更换头像</span>
                    <span className="hit_str">*单张图片大小限制2M，仅支持图片格式</span>
                    <div onClick={()=>up_img.current.click()} className="green_btn">上传头像</div>
                    <span className="norm_str">昵称</span>
                    <input className="profile_input" value={user_nick} onChange={(e)=>set_user_nick(e.target.value)} maxLength={15} />
                    <div onClick={()=>save_nick()} className="green_btn">保 存</div>
                </div>
                <div className="avt_view">
                    <span className="norm_str">卡片预览</span>
                    <div className="avt_panel">
                        <div className="avt_panel_top"></div>
                        <img className="avt_img" src={user_avt} />
                        <div className="avt_nick">{user_nick}</div>
                        
                    </div>
                </div>
            </div>
        )
    }

    function My_money()
    {
        const [user_money,set_user_money]=useState(0);
        const [mobile,set_mobile]=useState('');
        const [cz_money,set_cz_money]=useState();
        const [wxpay_panel,set_wxpay_panel]=useState(false); 
        const  [wxpay_status,set_wxpay_status]=useState(false);
        const [qrcode_value,set_qrcode_value]=useState('');
        const [qrcode_serial,set_qrcode_serial]=useState('');
       
        useEffect(()=>{
            send_data({"api":"get_user_profile"},(e)=>{
               set_user_money(e.money);
               set_mobile(e.mobile);
            })
        },[])
        function Pay_ok()
        {
            return(
                <div className="pay_ok">
                    <div className="ok_img" />
                        支付成功
                </div>
            )
        }
        function Wx_pay()
        {
            function get_serial_status()
            {
                send_data({"api":"get_wxpay_status","serial":qrcode_serial},(e)=>{
                    set_wxpay_status(e.status==0?false:true);
                 })
            }          
            useEffect(()=>{
                let timerId=setInterval(get_serial_status,1000);
                return()=>{
                    clearInterval(timerId);
                    send_data({"api":"get_user_profile"},(e)=>{
                        set_user_money(e.money);
                        set_mobile(e.mobile);
                     })
                }
            },[])
            return(
                <div className="wxpay_box">
                    <div className="wx_logo">
                    <div onClick={()=>set_wxpay_panel(false)} className="close_btn fl_right"></div>
                    </div> 
                    <div className="wx_qr_box">
                        <div className="qr_left">
                            <span className="norm_str">订单类型：购买进画积分</span> 
                            <span className="norm_str">订单号：{qrcode_serial}</span> 
                            <span className="norm_str">充值账号：{mobile}</span> 
                            <span className="norm_str">支付金额：{cz_money}元</span> 
                            <span className="norm_str">购买积分：{cz_money}分</span> 
                        </div>
                        <div className="qr_right">
                            <QRCode
                            className="qrcode"
                            size={256}
                            value={qrcode_value}
                            />
                            <div className="pay_money_title">打开微信扫一扫</div>
                            <div className="pay_money">￥{cz_money}</div>
                        </div>
                        {wxpay_status==true?<Pay_ok />:<></>}
                    </div>
                </div>
            )
        }
        function hand_cz_money(e)
        {
            var value=e.target.value;
            if (isNaN(value)) {
               alert('请输入数字');
               return;
            }        
            if(value>10000){
                alert('单笔最大支持1万元');
                return
            }    
            set_cz_money(value);
        }
        function get_wxpay_serial()
        {
            let cu_cz_money=parseFloat(cz_money).toFixed(2);
            set_cz_money(cu_cz_money);
            send_data({"api":"get_wx_pay","money":cu_cz_money},(e)=>{
                set_qrcode_value(e.code_url);
                set_qrcode_serial(e.serial);
                set_wxpay_panel(true);
            })
            
        }

        

        return(
            <div className="my_profile">
                <div className="change_avt">
                    <span className="norm_str my_money">{user_money}</span>
                    <span className="norm_str">充值积分</span>
                    <div onClick={(e)=>{set_cz_money(e.target.getAttribute("val"))}} className="money_box">
                        <span val="10">10</span>
                        <span val="50">50</span>
                        <span val="100">100</span>
                        <span val="500">500</span>
                    </div>
                    <input className="profile_input" value={cz_money} placeholder="10元等于10积分" onChange={(e)=>hand_cz_money(e)} maxLength={10} />
                    <span className="hit_str">充值积分只能用于进画消费，且进画只支持原路退回至付款账号</span>
                    {cz_money>=0.01?<div onClick={()=>get_wxpay_serial()} className="green_btn">充 值</div>:<></>}             
                </div>  
                {wxpay_panel==true?<Wx_pay />:''}             
            </div>
        )
    }

    let out_data;
    switch(cu_message_page)
    {
        case '个人资料':out_data=<My_profile />;break;
        case '我的积分':out_data=<My_money />;break;
        case '实名认证':out_data=<My_profile />;break;
        case '安全中心':out_data=<My_profile />;break;
    }
    return(
        <>
            <div className="user_center">
                <div className="user_title">{cu_message_page}</div>
                {out_data}
            </div>
        </>
    )
}

export default User_center;