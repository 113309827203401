import { useEffect, useRef, useState } from "react";
import { send_data,Model_set} from "./lib";

function Modelist_side(props)
{
    const [right_state,set_right_state]=useState(false);
    const [model_img_data,set_mode_img_data]=useState([]);
    const [cu_img,set_cu_img]=useState('');
    const [cu_model_name,set_cu_model_name]=useState('');
    const [cu_lage,set_cu_lage]=useState('');
    const input_box=props.input_box;

    const cu_lage_ref=useRef(cu_lage);

    function load_model_img()
    {
        var sd_model_checkpoint=localStorage.getItem("sd_model_checkpoint");  
        if(sd_model_checkpoint==null || sd_model_checkpoint=='')
        {
            sd_model_checkpoint="Anime"
        }   
        var last_model=localStorage.getItem("last_model");           
        var last_lage=localStorage.getItem("last_lage");           
     
        if(last_model==sd_model_checkpoint && last_lage==cu_lage_ref.current)
        {
            return;
        }       
        last_model=sd_model_checkpoint;
        localStorage.setItem("last_model",sd_model_checkpoint); 
        localStorage.setItem("last_lage",cu_lage_ref.current);
        send_data({"api":"get_model_img","model":sd_model_checkpoint,fanyi:cu_lage_ref.current},(e)=>{
            if(e.model!=null)
            {
                set_mode_img_data(e.model);
                set_cu_img(e.model[0]);
                set_cu_model_name(e.model[0].model);
            }
            
        })
    }
    useEffect(()=>{
        cu_lage_ref.current=cu_lage;
    })
    useEffect(()=>{   
        localStorage.setItem("last_model",'');         
        load_model_img();
        let timerid=setInterval(load_model_img,1000);
        return () => {
            clearInterval(timerid);
          };
    },[])     
          
    function Model_contex(){
        function dohand_tiger_words(val)
        {
            const regex = new RegExp(val, "i"); 
            if (regex.test(input_box.current.value)) {
                return;
            } 
            
            if(input_box.current.value=='')
            input_box.current.value=val;
            else
            input_box.current.value=input_box.current.value+","+val;
        }
        function Tiger_words(){
            return(
                <div className="Trigger_Words_box">
                    <div className="Trigger_Words_box_title">触发词：</div>
                    <div className="words_panel">
                        {                        
                            cu_img.Trigger_Words.split(",").map((val,index)=>{
                                return(
                                    <span key={index} onClick={()=>dohand_tiger_words(val)} >{val}</span>
                                )
                            })
                        }
                    </div>
                    
                </div>
            )
        }
        function hand_lage(val)
        {
            set_cu_lage(val);
            load_model_img();
        }
        return(
            <>
            <div className="model_title">模型参考</div>
            <div className="model_side">                
                <div className="model_name_box">{cu_model_name}</div>
                <div className="model_img_list">                    
                    {
                        model_img_data.map((items,index)=>{
                        return(
                            <div key={index}>
                                <img src={"https://ai.anyidata.com/checkpoing_img/"+items.image} onClick={()=>set_cu_img(items)} />
                            </div>  
                        )
                        })                       
                    
                    }
                </div>
                
                {cu_img.Trigger_Words!=null?<Tiger_words />:''}
             
                <div className="model_prompt">
                    提示词：
                    <div>
                    {cu_img.prompt_text}
                    </div>
                </div>
                <div className="model_prompt">
                    反向提示词：
                    <div>
                    {cu_img.negivate_txt}
                    </div>
                </div>
                <div className="lage_box">
                    <div>提示词显示为：</div>
                        <select onChange={(e)=>hand_lage(e.target.value)} value={cu_lage} className="lag_box_sel">                        
                            <option value="">默认</option>
                            <option  value="zh">中文</option>
                            <option  value="en">English</option>
                            <option  value="fr">Français</option>
                            <option  value="pt">Português</option>
                            <option  value="it">Italiano</option>
                            <option  value="de">Deutsch</option>
                            <option  value="ja">日本語</option>
                            <option  value="es">Español</option>
                            <option  value="chm">Русский язык</option>
                            <option  value="ar">العربية</option>
                            <option  value="ko">한국어</option>
                        </select>
                </div>
                
                <Model_set long_att={true} title="采样方法" value={cu_img.sampler} />
                <Model_set long_att={true} title="Seed" value={cu_img.seed} />
                <Model_set title="CFG权重" value={cu_img.cfg} />                     
                <Model_set title="迭代步数" value={cu_img.steps} />                 
                <Model_set title="Clip" value={cu_img.clip} /> 
                <div className="model_img_box">
                    <img src={"https://ai.anyidata.com/checkpoing_img/"+cu_img.image} />
                </div> 
            </div>  
            </>
        )
    }
    return(
        <div className={right_state==true?'message_right_side_open':'message_right_side_close'}>
            <div onClick={()=>{set_right_state(!right_state)}} className={right_state==true?'right_side_setbtm set_close':'right_side_setbtm'}></div>
            {right_state==true?<Model_contex />:''}               
        </div>
    )
}


export default Modelist_side