import Contex_page from "./contex";
import Modelist_side from "./model_side";
import User_center from "./user";

function Right_side(props)
{
        const history=props.history;
        const cu_message_page=props.cu_message_page;
        const scroll_box=props.scroll_box;
        const input_box=props.input_box;
        const up_img=props.up_img;
  
    //绘图页面
    function Message_side()
    {
        return(
            <div className="message_side" >
               <Contex_page history={history} scroll_box={scroll_box} input_box={input_box} up_img={up_img} cu_message_page={cu_message_page} />
            </div>
        )
    }
    //个人中心页面
    function Usercenter_side()
    {
        return(
            <div className="message_side">
                <User_center cu_message_page={cu_message_page} />
            </div>
        )
    }
    function Gpt_panel(e)
    {
        return(
            <>
                <iframe src="https://112.18.11.68:7861/" className="gpt_panel"/>
            </>
        )
    }
    let output_side;
    switch(cu_message_page)
    {
        case '创作大厅':
        case '交流':
        case '常见问题':
        case '公告':output_side=<Message_side />;break;
        case '个人资料':
        case '我的积分':
        case '实名认证':
        case '安全中心':output_side=<Usercenter_side />;break;
        case 'GPT':output_side=<Gpt_panel />;break;
        default:return;
    }
    return(
        <div className="right_side">
            {output_side}          
            {/* {cu_message_page=='创作大厅'?<Modelist_side input_box={input_box} />:''} */}
        </div>
    )
}


export default Right_side;