import { useEffect,useState,useRef} from "react";
import {send_data,download,Show_img_list} from "./lib";
import Stable_Diffusion_avt from './res/ai.png';

//拖动条
import { Slider } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

function Contex_page(props)
{
    const history=props.history;
    //消息列表
    const [cu_message_list,set_cu_message_list]=useState([]);   

    //设置滚动状态
    const [scroll_status,set_scroll_status]=useState(true);

    const cu_message_page=props.cu_message_page;

    

    const [user_images,set_user_images]=useState([]);

    //全屏图片
    const [full_img_status,set_full_img_status]=useState(false);
    const [full_img_index,set_full_img_index]=useState(0);
    //全屏用户图和名称
    const [[avt,nick],set_full_avtnick]=useState(['','']);
    

    //图生图框
    const [img2img_url,set_img2img_url]=useState('');
    const [img2img_url_minni,set_img2img_url_minni]=useState('');

    const scroll_box=props.scroll_box;
    const input_box=props.input_box;
    const up_img=props.up_img;


    const cu_message_list_ref=useRef(cu_message_list);
    const full_img_status_ref=useRef(full_img_status);


    //发送状态
    const [send_status,set_send_status]=useState(false);


    //========================设置页面========================

    //绘图设置页面
    const [model_panel,set_model_panel]=useState(false);
    const [size_panel,set_size_panel]=useState(false);
    const [samp_panel,set_samp_panel]=useState(false);
    const [setp_panel,set_setp_panel]=useState(false);
    const [cfg_panel,set_cfg_panel]=useState(false);
    const [draw_panel,set_draw_panel]=useState(false);
    const [zoom_panel,set_zoom_panel]=useState(false);
    const [vae_panel,set_vae_panel]=useState(false);
    const [seed_panel,set_seed_panel]=useState(false);
    const [clip_panel,set_clip_panel]=useState(false);
    const [lora_panel,set_lora_panel]=useState(false);

    //当前绘画选择的模型
    const [sd_model_checkpoint,set_sd_model_checkpoint]=useState("Anime");
    const [sd_model_img,set_sd_model_img]=useState("https://ai.anyidata.com/style_data/anime.png");
    //当前绘画比例
    const [sd_made_size,set_sd_made_size]=useState("1:1");
    //采样方法
    const [samp_med,set_samp_med]=useState("DPM++ 2M Karras");
    //迭代步数
    const [setp_nums,set_setup_nums]=useState("25");
    //CFG权重
    const [cfg_nums,set_cfg_nums]=useState("7");
    //重绘幅度
    const [denoising_strength,set_denoising_strength]=useState("0.7");
    //放大算法
    const [zoom_name,set_zoom_name]=useState("R-ESRGAN 4x+ Anime6B");

    //VAE模型
    const [vae_name,set_vae_name]=useState("sdxl_vae.safetensors");

    //SEED
    const [seed_nums,set_seed_nums]=useState(-1);
    //CLIP
    const [clip_nums,set_clip_nums]=useState(1);


    function to_usercenter(uid)
    {
        localStorage.setItem("home_user_id",uid);
        history.push('/user_home');
    }
    
    // function Full_show(){
    //     return(                        
    //         <Viewer                
    //             visible={full_img_status}
    //             activeIndex={full_img_index}                
    //             noLimitInitializationSize={true}
    //             onClose={() => { set_full_img_status(false); } }
    //             images={full_img_url}
    //         />
    //     )
    // }
    
    useEffect(() =>{
        cu_message_list_ref.current = cu_message_list;
        full_img_status_ref.current=full_img_status;
    });
    
    function get_message_list()
    {
        if(full_img_status_ref.current==false)
        {
            let send_par={"api":"get_message_list","mold":cu_message_page};
            send_data(send_par,(e)=>{
                if(e.status=='success' && e.message!=null)
                {
                    if(JSON.stringify(cu_message_list_ref.current)!=JSON.stringify(e.message))
                    {
                        set_cu_message_list(e.message);
                    }                     
                }
                
            });
        }
        
    }       

    function set_show_img(image,index)
    {
            var image_list_data=[];
            const cu_image_data=JSON.parse(image.sd_img);               
            for (const index in cu_image_data) {
                const tm_data={
                    "url":cu_image_data[index],
                    "start":eval("image.start"+index),
                    "eval":eval("image.eval"+index),                      
                    "id":image.id,
                    "index":index
                };     
                image_list_data.push(tm_data);
            }   
            set_full_avtnick([image.avt,image.nick]);             
         
            set_user_images(image_list_data);
            set_full_img_index(index);
            set_full_img_status(true);
    }
    
    function Message_line(props){ 
        const [img_sel,set_img_sel]=useState(0);                   

        function Prompt_box(){
            return(
                <>                                
                    {props.msd.prompt==''?'':<><span className="prompt_text">Prompt：</span>{props.msd.prompt}<br></br></>}                         
                    {props.msd.negivate==''?'':<><span className="neprompt_text">Negivate Prompt：</span>{props.msd.negivate}</>}
                </>
            )
        }
        //高清放大作品
        function hand_pro_img(msg_id,sd_img,img_index,prompt,negivate)
        {
            if(window.confirm("确定要进行高清放大此图？"))
            {
                let img_data=JSON.parse(sd_img);
                let img_path=img_data[img_index-1];


                let cu_text='';
                if(prompt!=null)
                cu_text="--prompt "+prompt;
                else
                cu_text='';

                if(negivate!=null && negivate!='')
                {
                    if(cu_text!='')
                    cu_text+=" --na "+negivate;
                    else
                    cu_text+="--na "+negivate;
                }
                if(cu_text!='')
                {
                    cu_text+=" --img "+img_path;
                }
                else
                    cu_text="--img "+img_path;          
                    
                    
                set_scroll_status(true);
                let send_par={
                    "api":"write_msg",
                    "msg_id":msg_id,
                    "batch_size":1,
                    "sampling_method":"DPM adaptive",
                    "cfg":7,
                    "steps":25,
                    "sc_med":zoom_name,
                    "sc_scal":3,
                    "denoising_strength":0.2,//重绘幅度
                    "prompt":cu_text,        
                    "check_point":sd_model_checkpoint
                }
                send_data(send_par,(e)=>{                         
                    get_message_list(); 
                });
            }
            


        }
        //图生图
        function hand_img2img(sd_img,img_index,prompt,negivate)
        {
            let img_data=JSON.parse(sd_img);
            let img_path=img_data[img_index-1];
            let cu_text='';
            if(prompt!=null)
            cu_text=prompt;
            else
            cu_text='';

            if(negivate!=null && negivate!='')
            {
                if(cu_text!='')
                cu_text+=" --na "+negivate;
                else
                cu_text+="--na "+negivate;
            }

            set_img2img_url(img_path);
            set_img2img_url_minni(replaceImageName(img_path));
            if(input_box.current.value!='')return false;
            input_box.current.value=cu_text;
            input_box.current.focus();
        }
        
        function Img_sel(){
            return(
                <>
                    {/* {img_sel==0?"":<div className={props.msd.mold!="img"?["pro_img pro_img_"+img_sel]:["pro_img pro_img_only_one pro_img_"+img_sel]} >
                        {props.msd.mold!="img"?<div onClick={()=>hand_pro_img(props.msd.id,props.msd.sd_img,img_sel,props.msd.prompt,props.msd.negivate)} title="将此图生成最终高质量作品" >❤️生成</div>:<></>}
                        <div onClick={()=>hand_img2img(props.msd.sd_img,img_sel,props.msd.prompt,props.msd.negivate)} title="以此图风格进行变换创作">🎨创作</div>
                    </div>}  */}
                </>
            )
        }   
        
        function replaceImageName(filename) {
            return filename.replace(/\.png$/, '_mini.png');
        }
  
        
        function Start_nums_mini_panel(props)
        {
            return(
               
                    <div onClick={()=>set_show_img(props.msd,props.index)} className={props.cls}>
                        <div className="start_nums no_repeat">{props.start}</div>
                        <div className="eval_nums no_repeat">{props.eval}</div>
                    </div>               
            )
        }

        let out_div;
        let out_html;

        if(cu_message_page=='创作大厅')
        {
            if(props.msd.status=="0")
            {
                out_div=
                <div className="message_title">
                <Prompt_box />
                <span className="by_wait">Wait for start...</span>
                </div>;
            }
            else if(props.msd.status=="1")
            {
                out_div=
                <>
                    <div className="message_title">
                    <Prompt_box /> 
                    <span className="by_wait">{props.msd.process==null?0:props.msd.process}%</span>
                    </div>
                    <div className="message_image">
                    {props.msd.base64_img!=null?<img className="processimg" src={props.msd.base64_img} />:<></>}
                    </div>
                </>
            }
            else if(props.msd.status=="2")
            {
                let images_data=JSON.parse(props.msd.sd_img);                
                //用户发送的图片
                if(props.msd.mold=='img')
                {
                    out_div=<>
                        <div className="message_title">                            
                        </div>
                        <div>                   
                            <div className="flxe_div">
                                {img_sel>=1 && img_sel<=3?<Img_sel />:""}                                     
                                <img className="moreimg" onMouseOver={()=>{set_img_sel(1)}} onClick={()=>set_show_img(props.msd,0)} src={images_data[0]} />
                            </div>                                                                
                        </div>
                        </>
                }
                else
                {
                    if(images_data.length==1)
                    {
                        out_div=<>
                        <div className="message_title">                            
                        <Prompt_box />                            
                        </div>
                        <div >                   
                            <div>
                                <img className="one_moreimg" onClick={()=>set_show_img(props.msd,0)} src={replaceImageName(images_data[0])} />
                                <div className="message_start_mainone message_start_main">
                                    <Start_nums_mini_panel cls="message_start_panel message_start_panelone" msd={props.msd} index={0} start={props.msd.start0} eval={props.msd.eval0}  />
                               </div>
                            </div> 
                            <div onClick={()=>{download(images_data[0],images_data[0].split('/').pop())}} className="donwload_btn">下 载({props.msd.file_size}MB)</div>
                        </div>
                        </>
                    }
                    else
                    {
                        out_div=<>
                        <div className="message_title">                            
                        <Prompt_box />                            
                        </div>
                        <div>                   
                            <div>
                                {img_sel>=1 && img_sel<=3?<Img_sel />:""}        
                                                         
                               <img className="moreimg" onMouseOver={()=>{set_img_sel(1)}} onClick={()=>set_show_img(props.msd,0)} src={replaceImageName(images_data[0])} />
                               <img className="moreimg" onMouseOver={()=>{set_img_sel(2)}}  onClick={()=>set_show_img(props.msd,1)} src={replaceImageName(images_data[1])} />
                               <img className="moreimg" onMouseOver={()=>{set_img_sel(3)}}  onClick={()=>set_show_img(props.msd,2)} src={replaceImageName(images_data[2])} />
                               
                               <div className="message_start_main">
                                    <Start_nums_mini_panel cls="message_start_panel" msd={props.msd} index={0} start={props.msd.start0} eval={props.msd.eval0}  />
                                    <Start_nums_mini_panel cls="message_start_panel" msd={props.msd} index={1} start={props.msd.start1} eval={props.msd.eval1}  />
                                    <Start_nums_mini_panel cls="message_start_panel" msd={props.msd} index={2} start={props.msd.start2} eval={props.msd.eval2}  />
                               </div>
                                {props.msd.img_seed!=null && props.msd.img_seed!='null'?
                                <>
                                <div className="seed_title">SEED</div>
                                <div title="点击设置为此随机种子" className="seed_panel">                                    
                                    <div onClick={()=>set_seed_nums(props.msd.img_seed)}>{props.msd.img_seed}</div><div onClick={()=>set_seed_nums(Number(props.msd.img_seed)+1)}>{Number(props.msd.img_seed)+1}</div><div onClick={()=>set_seed_nums(Number(props.msd.img_seed)+2)}>{Number(props.msd.img_seed)+2}</div>
                                </div></>
                                :''} 
                                                    
                            </div>                                                                
                        </div>
                        </>
                    }
                    
                }
            }
            out_html=<div className="message_line">
                            <div className="message_head">
                                <div onClick={()=>to_usercenter(props.msd.uid)} className="user_message_icon">
                                    <img src={props.msd.avt!=null?props.msd.avt:Stable_Diffusion_avt} />                                    
                                </div>      
                                <span onClick={()=>to_usercenter(props.msd.uid)} className="by_user">@{props.msd.nick!=null?props.msd.nick:props.msd.by_user}</span>
                                <div className="message_time">{props.msd.ip_location} {props.msd.dateline}</div>                                 
                            </div>   
                            {out_div}               
                    </div>  
        }
        else
        {
            if(props.msd.message_type=="txt")
            {
                out_div=
                <div className="message_title">           
                <span className="by_text">{props.msd.text}</span>
                </div>;
            }
            else
            {
                out_div= <div>                   
                            <div className="flxe_div">
                                <img className="moreimg" onMouseOver={()=>{set_img_sel(1)}} onClick={()=>set_show_img(props.msd,0)} src={props.msd.img} />
                            </div>                                                                
                        </div>
            }
            
                
            out_html=<div className="message_line">
                            <div className="message_head">
                                <div onClick={()=>to_usercenter(props.msd.uid)} className="user_message_icon">
                                    <img src={props.msd.avt!=null?props.msd.avt:Stable_Diffusion_avt} />
                                </div>      
                                <div onClick={()=>to_usercenter(props.msd.uid)} className={props.msd.mobile=='进画'?'message_master':'message_user'}>{props.msd.nick!=null?props.msd.nick:props.msd.mobile}</div> 
                                <div className="message_time">{props.msd.mobile=='进画'?'':props.msd.ip_location} {props.msd.dateline}</div>                                 
                            </div>   
                        {out_div}               
                    </div> 
        }

        return(
            <>                    
                {out_html}
            </>
        )
    }


    //发送命令
    function message_handleclick(e){   
        
        close_set();

        if(window.event.keyCode==13 && e.target.value.replace(/\s/g,"")=='')
        {
            e.preventDefault();
            return;
        }
        
        
        if(window.event.keyCode==13 && e.target.value.replace(/\s/g,"")!='')
        {
                e.preventDefault(); 
                let cu_text=e.target.value; 
                do_send(cu_text);        
        }
    }   

   function do_send(cu_text)
   { 
        if(send_status==true)
        {
            return false;    
        }
        set_send_status(true);  

                set_scroll_status(true);                       
                let send_par
                if(cu_message_page=='创作大厅')
                {                
                    if(img2img_url!='')
                    {
                        send_par={
                            "api":"write_msg",
                            "batch_size":3,
                            "sampling_method":samp_med,
                            "cfg":cfg_nums,
                            "steps":setp_nums,
                            "prompt":"--prompt "+cu_text+" --img "+img2img_url,
                            "denoising_strength":denoising_strength,
                            "vae":vae_name,
                            "size":sd_made_size,
                            "seed":seed_nums,
                            "clip":clip_nums,
                            "check_point":sd_model_checkpoint
                            }
                    }
                    else
                    {
                        send_par={
                            "api":"write_msg",
                            "batch_size":3,
                            "sampling_method":samp_med,
                            "cfg":cfg_nums,
                            "steps":setp_nums,
                            "prompt":"--prompt "+cu_text,
                            "vae":vae_name,
                            "size":sd_made_size,
                            "seed":seed_nums,
                            "clip":clip_nums,
                            "check_point":sd_model_checkpoint
                            }
                    }  
                }
                else
                {
                    send_par={
                        "api":"write_user_msg",
                        "mold":cu_message_page,
                        "text":cu_text                        
                        }
                } 

                set_img2img_url('');
                set_img2img_url_minni('');
                send_data(send_par,(e)=>{   
                    set_send_status(false); 
                    if(e.status=='nsfw')
                    {
                        alert('内容含有敏感词汇，请重新输入');
                        return;
                    }
                    if(e.status!='success')
                    {
                        alert(e.status);
                        return;
                    }
                    input_box.current.value='';                   
                    get_message_list(); 
                });
   }
    //模型列表
    function Model_panel(){
        
        const [model_list,set_model_list]=useState([]);                

        useEffect(()=>{
            send_data({"api":"get_check_point"},(e)=>{
                set_model_list(e.model);
            })
        },[]);


        function sel_model(model,model_img)
        {
            set_sd_model_checkpoint(model);
            set_sd_model_img(model_img);
            set_model_panel(false);

            localStorage.setItem("sd_model_checkpoint",model);
            localStorage.setItem("sd_model_img",model_img);
        }

        
                
        return(
            <div className="set_panel">
                <div className="set_contex_panel">
                    {model_list.map((items)=>{
                        return(
                            <div key={items.id} onClick={()=>sel_model(items.sd_model_checkpoint,items.model_img)} className="model_list">
                                <div className="model_name">{items.mode_mark}</div>
                                <div className="model_mark"></div>
                                <img src={items.model_img} />
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
    //比例列表
    function Resize_panel(){
        function sel_size(size)
        {
            localStorage.setItem("sd_made_size",size);
            set_sd_made_size(size);
            set_size_panel(false);
        }
        function Size_box(props)
        {
            return(
                <div className="size_box" onClick={()=>sel_size(props.data)}>                            
                    <div className="resize_div">
                        <div className={"resize_"+props.re_size}>
                            {props.data}
                        </div>
                    </div>
                </div>
            )
        }
        return(
            <div className="data_panel flex_row">
                    <Size_box data="1:1" re_size="1"  />               
                    <Size_box data="4:3" re_size="2"  />               
                    <Size_box data="3:4" re_size="3"  />               
                    <Size_box data="16:9" re_size="4" />               
                    <Size_box data="9:16" re_size="5" />               
            </div>
        )
    }

    //采样方法
    function Samp_panel(){
        const [samp_list,set_samp_list]=useState([]);
        function dohandel(val)
        {
            localStorage.setItem("samp_med",val);
            set_samp_med(val);
            set_samp_panel(false);
        }
        function Line_data(props){
            return(
                <div className="samp_box" onClick={()=>dohandel(props.name)}>
                    {props.name}
                </div>
            )
        }   
        useEffect(()=>{
            send_data({"api":"get_samp"},(e)=>{
                set_samp_list(e);
            })
        },[]);
        return(
            <div className="data_panel flex_col">
                <span className="med_hint">【采样方法】使用哪种算法生成图像</span>
                 {                    
                    samp_list.map((items,index)=>{
                        return(
                            <Line_data key={index} name={items.name} />
                        )
                    })
                 }       
                       
            </div>
        )
    }
    //迭代步数
    function Setp_panel(){
        const [cu_val,set_cu_val]=useState(30);
        function dohandel(val)
        {
            localStorage.setItem("setp_nums",val);
            set_setup_nums(val);
            set_setp_panel(false);
        }   
        return(
            <div className="data_panel flex_col">
                <span className="med_hint">【迭代步数】迭代改进生成的图像多少次；更高的值需要更长的时间；非常低的值会产生不好的结果,通常设置在25-35之间</span>
                <div className="slider_box">
                        <Slider
                                min={20}
                                max={50}
                                progress
                                defaultValue={setp_nums}
                                onChange={value => {
                                    set_cu_val(value);
                                }}                                
                            />
                </div>    
                <div className="med_conbtn" onClick={()=>dohandel(cu_val)}>确定</div> 
                       
            </div>
        )
    }
    //CFG
    function Cfg_panel(){
        const [cu_val,set_cu_val]=useState(7);
        function dohandel(val)
        {
            localStorage.setItem("cfg_nums",val);
            set_cfg_nums(val);
            set_cfg_panel(false);
        }   
        return(
            <div className="data_panel flex_col">
                <span className="med_hint">【CFG权重】无分类器指导信息影响尺度(Classifier Free Guidance Scale) - 图像应在多大程度上服从提示词 - 较低的值会产生更有创意的结果</span>
                <div className="slider_box">
                        <Slider
                                min={1}
                                max={30}
                                step={0.5}
                                progress
                                defaultValue={cfg_nums}
                                onChange={value => {
                                    set_cu_val(value);
                                }}                                
                            />
                </div>    
                <div className="med_conbtn" onClick={()=>dohandel(cu_val)}>确定</div> 
                        
            </div>
        )
    }
    //重绘幅度
    function Draw_panel(){
        const [cu_val,set_cu_val]=useState(0.7);
        function dohandel(val)
        {
            localStorage.setItem("denoising_strength",val);
            set_denoising_strength(val);
            set_draw_panel(false);
        }   
        return(
            <div className="data_panel flex_col">
                <span className="med_hint">【重绘幅度】决定算法对图像内容的影响程度。设置 0 时，什么都不会改变，而在 1 时，你将获得不相关的图像。值低于 1.0 时，处理的迭代步数将少于“采样迭代步数”滑块指定的步数</span>
                <div className="slider_box">
                        <Slider
                                min={0.1}
                                max={1.0}
                                step={0.1}
                                progress
                                defaultValue={denoising_strength}
                                onChange={value => {
                                    set_cu_val(value.toFixed(1));
                                }}                                
                            />
                </div>    
                <div className="med_conbtn" onClick={()=>dohandel(cu_val)}>确定</div> 
                        
            </div>
        )
    }
    //放大算法
    function Zoom_panel(){
        const [zoom_list,set_zoom_list]=useState([]);
        function dohandel(val)
        {
            localStorage.setItem("zoom_name",val);
            set_zoom_name(val);
            set_zoom_panel(false);
        }
        function Line_data(props){
            return(
                <div className="samp_box" onClick={()=>dohandel(props.name)}>
                    {props.name}
                </div>
            )
        }   
        useEffect(()=>{
            send_data({"api":"get_zoom"},(e)=>{
                set_zoom_list(e);
            })
        },[]);
        return(
            <div className="data_panel flex_col">
                <span className="med_hint">【放大算法】使用哪种算法放大图像</span>
                    {                    
                    zoom_list.map((items,index)=>{
                        return(
                            <Line_data key={index} name={items.name} />
                        )
                    })
                    }       
                        
            </div>
        )
    }
    //Vae模型
    function Vae_panel(){
        const [vae_list,set_vae_list]=useState([]);
        function dohandel(val)
        {
            localStorage.setItem("vae_name",vae_name);
            set_vae_name(val);
            set_vae_panel(false);
        }
        function Line_data(props){
            return(
                <div className="samp_box" onClick={()=>dohandel(props.name)}>
                    {props.name}
                </div>
            )
        }   
        useEffect(()=>{
            send_data({"api":"get_vae"},(e)=>{
                set_vae_list(e.model);
            })
        },[]);
        return(
            <div className="data_panel flex_col">
                <span className="med_hint">【VAE模型】使用哪种VAE模型生成图像</span>
                    {                    
                    vae_list.map((items,index)=>{
                        return(
                            <Line_data key={index} name={items.sd_model_checkpoint } />
                        )
                    })
                    }       
                        
            </div>
        )
    }
    //CLIP
    function Clip_panel(){
        const [cu_val,set_cu_val]=useState(1);
        function dohandel(val)
        {
            localStorage.setItem("clip_nums",val);
            set_clip_nums(val);
            set_clip_panel(false);
        }   
        return(
            <div className="data_panel flex_col">
                <span className="med_hint">【CLIP】CLIP模型的早期停止参数；1是像往常一样停在最后一层，2是停在倒数第二层，等等。</span>
                <div className="slider_box">
                        <Slider
                                min={1}
                                max={12}
                                progress
                                defaultValue={clip_nums}
                                onChange={value => {
                                    set_cu_val(value);
                                }}                                
                            />
                </div>    
                <div className="med_conbtn" onClick={()=>dohandel(cu_val)}>确定</div> 
                       
            </div>
        )
    }
    //SEED
    function Seed_panel(){
        const [cu_val,set_cu_val]=useState(-1);
        useEffect(()=>{
            set_cu_val(seed_nums);
        },[])
        function dohandel(val)
        {
            localStorage.setItem("seed_nums",val);
            set_seed_nums(val);
            set_seed_panel(false);
        }   
        function setdefault()
        {
            localStorage.setItem("seed_nums",-1);
            set_seed_nums(-1);
            set_seed_panel(false);
        }
        return(
            <div className="data_panel flex_col">
                <span className="med_hint">【SEED】一个固定随机数生成器输出的值 - 以相同参数和随机种子生成的图像会得到相同的结果,将随机种子设置为-1，则每次都会使用一个新的随机数；设定随机种子，以便固定输出结果</span>
                        <input onChange={(e)=>set_cu_val(e.target.value)} className="seed_input" value={cu_val} />
                <div className="panel_btn">
                    <div className="med_conbtn" onClick={()=>setdefault()} >重置</div> 
                    <div className="med_conbtn" onClick={()=>dohandel(cu_val)} >确定</div> 
                </div>
                       
            </div>
        )
    }
    //LORA
    function Lora_panel(){
        
        const [model_list,set_model_list]=useState([]);                

        useEffect(()=>{
            send_data({"api":"get_lora"},(e)=>{
                set_model_list(e.model);
            })
        },[]);

    
        function sel_model(model,val,model_img)
        {
            set_lora_panel(false);
            let cu_lora='<lora:'+model+':1>';
            const regex = new RegExp(cu_lora, "i"); 
            if (regex.test(input_box.current.value)) {
                return;
            }             
            input_box.current.value=cu_lora+input_box.current.value;            
        }

        
                
        return(
            <div className="set_panel">
                <div className="set_contex_panel">
                    {model_list.map((items)=>{
                        return(
                            <div key={items.id} onClick={()=>sel_model(items.sd_model_checkpoint,1,items.model_img)} className="model_list">
                                <div className="model_name">{items.sd_model_checkpoint}</div>
                                <div className="model_mark"></div>
                                <img src={items.model_img} />
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
    //上传图片
    const handleFileInputChange = async (event) => {
        const file = event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);                
        reader.onload = function(e) 
        {
            if (reader.readyState === 2) 
            {
                send_data({"api":"up_img","img":e.target.result,"file_type":file.type,"mold":cu_message_page},(e)=>{
                })
            }
        }
    
        };

    useEffect(()=>{ 
        if(scroll_status)
        {
            scroll_box.current.scrollTop=scroll_box.current.scrollHeight; 
            input_box.current.focus();       
        }

    });
  
    function hand_med(med)
    {
        if(med==null)return;

        close_set();

        eval(med)(true);
              
        
    }
    function close_set()
    {
        set_model_panel(false);
        set_size_panel(false);
        set_samp_panel(false);
        set_setp_panel(false);
        set_cfg_panel(false);
        set_draw_panel(false);
        set_zoom_panel(false);
        set_vae_panel(false);
        set_seed_panel(false);
        set_clip_panel(false);
        set_lora_panel(false);
    }

    function load_default_set(){
        var sd_model_checkpoint=localStorage.getItem("sd_model_checkpoint");if(sd_model_checkpoint!=null)set_sd_model_checkpoint(sd_model_checkpoint);
        var sd_model_img=localStorage.getItem("sd_model_img");if(sd_model_img!=null)set_sd_model_img(sd_model_img);
        var sd_made_size=localStorage.getItem("sd_made_size");if(sd_made_size!=null)set_sd_made_size(sd_made_size);
        var samp_med=localStorage.getItem("samp_med");if(samp_med!=null)set_samp_med(samp_med);
        var setp_nums=localStorage.getItem("setp_nums");if(setp_nums!=null)set_setup_nums(setp_nums);
        var cfg_nums=localStorage.getItem("cfg_nums");if(cfg_nums!=null)set_cfg_nums(cfg_nums);
        var denoising_strength=localStorage.getItem("denoising_strength");if(denoising_strength!=null)set_denoising_strength(denoising_strength);
        var zoom_name=localStorage.getItem("zoom_name");if(zoom_name!=null)set_zoom_name(zoom_name);  
        var vae_name=localStorage.getItem("vae_name");if(vae_name!=null)set_vae_name(vae_name);  
        var seed_nums=localStorage.getItem("seed_nums");if(seed_nums!=null)set_seed_nums(seed_nums);  
        var clip_nums=localStorage.getItem("clip_nums");if(clip_nums!=null)set_clip_nums(clip_nums);  
        
   
    }
    useEffect(()=>{
        if(cu_message_page=='创作大厅')
        load_default_set();
        get_message_list();
        let timerid=setInterval(get_message_list,5000);
        return () => {
            clearInterval(timerid);
          };

    },[])  
    function Input_img2img_box()
    {
        const [cu_status,set_cu_status]=useState(false);
        return(
            <div className="input_img_panel">                
                <img  onMouseEnter={()=>set_cu_status(true)} className='img2img_input' src={img2img_url_minni} />
                {cu_status==true?<div onClick={()=>{set_img2img_url('');set_img2img_url_minni('')}} onMouseLeave={()=>set_cu_status(false)} className="remove_panel" >删除</div>:<></>}
            </div>
        )
    }
    function Img_tools_panel()
    {
        return(
            <> 
                 
                 <div onClick={(e)=>hand_med(e.target.getAttribute("cu_med"))}  className="set_box">
                    <input ref={up_img} onChange={handleFileInputChange} className="nodisplay" type="file" accept=".jpg,.png,.bmp,.jpeg" ></input>        
                    <div cu_med="set_model_panel" title="主模型，决定图像创作风格"  className="input_btn model_btn">风格{sd_model_img!=''?<img cu_med="set_model_panel" className="model_img" src={sd_model_img} />:''}</div>
                    <div cu_med="set_size_panel" title="画面比例"  className="input_btn resize_btn">比例<div className="showset">{sd_made_size}</div></div>
                    <div cu_med="set_samp_panel" title="使用哪种算法生成图像"  className="input_btn samp_btn">采样方法<div className="showset">{samp_med}</div></div>
                    <div cu_med="set_setp_panel" title="迭代改进生成的图像多少次；更高的值需要更长的时间；非常低的值会产生不好的结果"  className="input_btn setup_btn">迭代步数<div className="showset">{setp_nums}</div></div>
                    <div cu_med="set_cfg_panel" title="无分类器指导信息影响尺度(Classifier Free Guidance Scale) - 图像应在多大程度上服从提示词 - 较低的值会产生更有创意的结果"  className="input_btn cfg_btn">CFG权重<div className="showset">{cfg_nums}</div></div>
                    {img2img_url==''?'':<div cu_med="set_draw_panel" title="决定算法对图像内容的影响程度。设置 0 时，什么都不会改变，而在 1 时，你将获得不相关的图像。值低于 1.0 时，处理的迭代步数将少于“采样迭代步数”滑块指定的步数（图生图时可用）"  className="input_btn drawde_btn">重绘幅度<div className="showset">{denoising_strength}</div></div>}
                    <div cu_med="set_seed_panel" title="一个固定随机数生成器输出的值 - 以相同参数和随机种子生成的图像会得到相同的结果 将随机种子设置为-1，则每次都会使用一个新的随机数 重用上一次使用的随机种子，如果想要固定输出结果就会很有用"  className="input_btn seed_btn">SEED<div className="showset">{seed_nums}</div></div>
                    <div cu_med="set_vae_panel" title="模型" className="input_btn vae_btn">VAE<div className="showset">{vae_name}</div></div>
                </div>
           
            </>
        )
    }
    function Mini_upimg()
    {
        return(
            <>
                <input ref={up_img} onChange={handleFileInputChange} className="nodisplay" type="file" accept=".jpg,.png,.bmp,.jpeg" ></input> 
                <div title="上传" onClick={()=>{up_img.current.click();close_set()}} className="up_btn_mini"></div>
            </>
        )
    }
    return(
        <>    
            {full_img_status==true?<Show_img_list margin_left={true}  full_img_index={full_img_index} full_img_data={user_images} avt={avt} nick={nick}  set_full_img_status={set_full_img_status} />:''}
            {/* 全屏panel */}
            {/* {full_img_status==true?<Full_show />:<></>}    */}

            {/* 消息列表 */}
            <div ref={scroll_box} onScroll={(e)=>{
                const { clientHeight, scrollHeight, scrollTop } = e.target;
                const isBottom = scrollTop + clientHeight + 20+400 > scrollHeight;
                set_scroll_status(isBottom);
            }} className="message_box" onClick={()=>{
                close_set()
            }}>   
                {                            
                    cu_message_list.map((items)=>{
                        return(
                            <Message_line key={items.id} msd={items} />               
                        )
                    })
                }
            </div>


            {model_panel==true?<Model_panel />:''}    
            {size_panel==true?<Resize_panel />:''}  
            {samp_panel==true?<Samp_panel />:''}
            {setp_panel==true?<Setp_panel />:''}
            {cfg_panel==true?<Cfg_panel />:''}
            {draw_panel==true?<Draw_panel />:''}
            {zoom_panel==true?<Zoom_panel />:''}
            {vae_panel==true?<Vae_panel />:''}
            {clip_panel==true?<Clip_panel />:''}
            {seed_panel==true?<Seed_panel />:''}
            {lora_panel==true?<Lora_panel />:''}
    
            <div className={cu_message_page=='创作大厅'?'bottom_box':'bottom_box_min'}>

                {/* 控制面板 */}
                {cu_message_page=='创作大厅'?<Img_tools_panel />:''}
                {/* 输入框              */}
                <div className={cu_message_page=='创作大厅'?'send_box':'send_box_min'}>  
                        {cu_message_page=='创作大厅'?'':<Mini_upimg />}                  
                        <div className={cu_message_page=='创作大厅'?'input_message':'input_message_min'}> 
                        {img2img_url!=''?<Input_img2img_box />:<></>}                                 
                        <textarea rows="1" cols="30" ref={input_box} placeholder={cu_message_page=='创作大厅'?'格式：提示词 --na 反向提示词':''} onClick={()=>{close_set()}} onKeyDown={(e)=>message_handleclick(e)} />
                        <div onClick={()=>{
                            close_set();
                            var cu_text=input_box.current.value;
                            if(cu_text.replace(/\s/g,"")=='')
                            {
                                return;
                            }
                                                       
                            input_box.current.value='';
                            do_send(cu_text);  
                        
                        }} className="sendmessage_btn"><div></div></div>
                        </div>
                </div> 
            </div>
           
                               
        </>
    )
}


export default Contex_page;