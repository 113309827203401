import { useEffect, useState} from "react";

import ai_icon from './res/ai.png';
import mypro_icon from './res/mypro.png';
import shop_icon from './res/shop.png';
import nft_icon from './res/nft.png';
import user_icon from './res/user.png';

function Left_side(props){
    const history=props.history;
    const set_main_menu_index=props.set_main_menu_index;
    const main_menu_index=props.main_menu_index;
    const set_cu_sec_mem_index=props.set_cu_sec_mem_index;
    const cu_sec_mem_index=props.cu_sec_mem_index;
    const set_cu_message_page=props.set_cu_message_page;

    function Main_menu()
    {  
        function Mem_btn(props){
            const [cu_class,set_cu_class]=useState("mem_btn");
            return(
                <div onMouseEnter={()=>set_cu_class("mem_btn mem_btn_in")} onMouseLeave={()=>set_cu_class("mem_btn mem_btn_out")}  data-self_index={props.self_index} style={{backgroundImage:`url(${props.icon})`}} className={cu_class}>
                    <div className="btn_tip">{props.title}</div>
                    {props.self_index==props.main_menu_index?<div className="mem_seld"></div>:''}
                </div>
            )
        }
        function do_main_menu(index)
        { 
            if(index=='' || index==null)return;       
            if(index=="首页")
            {
                localStorage.setItem("cu_page","login");
                window.location.reload();
                return;
            }
            //点击主菜单切换默认页
            set_main_menu_index(index);     
            if(index=="AI绘图")
            {
                set_cu_sec_mem_index("创作大厅");
                set_cu_message_page("创作大厅");                
            }
            else if(index=='个人中心')
            {
                set_cu_sec_mem_index("个人资料");
                set_cu_message_page("个人资料");
            } 
            else if(index=='GPT')
            {
                set_cu_sec_mem_index("GPT");
                set_cu_message_page("GPT");
            }          
        }
        return(
        <div onClick={(e)=>do_main_menu(e.target.dataset.self_index)} className="main_menu">                
                <Mem_btn self_index="首页"  main_menu_index={main_menu_index} title="首页" icon={shop_icon} />
                <Mem_btn self_index="AI绘图"  main_menu_index={main_menu_index} title="AI绘图" icon={ai_icon} />
                <Mem_btn self_index="个人中心"  main_menu_index={main_menu_index} title="个人中心" icon={user_icon} />
                <Mem_btn self_index="GPT"  main_menu_index={main_menu_index} title="GPT" icon={mypro_icon} />
        </div>
        )
    }
    function Sec_menu()
    {
        
        function Sec_menu_list(props){
            return(
                <div data-self_index={props.self_index} className={cu_sec_mem_index==props.self_index?"sec_menu_list sec_menu_list_seled":"sec_menu_list"}>
                    {props.title}
                </div>
            )
        }
        function Sec_menu_list_head()
        {
            return(
                <div className="sec_memu_list_head">
                    <div className="sec_list_head_title">进画 <span className="user_mobile">{localStorage.getItem("mobile")}</span></div>
                </div>
            )
        }

        function Sec_menu_sd(){        
            return(<>
                <Sec_menu_list self_index="创作大厅" title="● 创作大厅" />
                <Sec_menu_list self_index="交流" title="● 交流" />
                <Sec_menu_list self_index="常见问题" title="● 常见问题" />
                <Sec_menu_list self_index="公告" title="● 公告" />
            </>)
        }
        function Sec_menu_user(){      
            return(<>
                <Sec_menu_list self_index="个人资料" title="● 个人资料" />
                <Sec_menu_list self_index="我的积分" title="● 我的积分" />
                <Sec_menu_list self_index="我的作品" title="● 我的作品" />
                {/* <Sec_menu_list self_index="实名认证" title="● 实名认证" /> */}
                {/* <Sec_menu_list self_index="安全中心" title="● 安全中心" /> */}
            </>)
        }
        function Sec_gpt_menu()
        {
            return(
                <Sec_menu_list self_index="GPT" title="● CHAT GPT" />
            )
        }
        
        function Sec_menu_handclick(e){          
  
            if(e.target.dataset.self_index=='我的作品')
            {
                localStorage.setItem("home_user_id",localStorage.getItem("uid"));
                history.push('/user_home');
                return;

            }
            set_cu_message_page(e.target.dataset.self_index);
            set_cu_sec_mem_index(e.target.dataset.self_index);

        }
        
        return(
        <div className="sec_menu">
            <Sec_menu_list_head />
            <div onClick={(e)=>Sec_menu_handclick(e)}>
                {main_menu_index=="AI绘图"?<Sec_menu_sd />:''}
                {main_menu_index=="个人中心"?<Sec_menu_user />:''}
                {main_menu_index=="GPT"?<Sec_gpt_menu />:''}
            </div>
        </div>
        )
    } 
    
    return(
            <div className='left_side'>
                <Main_menu />                
                <Sec_menu />
            </div>            
    )
}

export default Left_side