import { useEffect, useRef, useState } from "react";
import { send_data,Show_img_list } from "./lib";
import Stable_Diffusion_avt from './res/ai.png';


function User_home(props)
{
    const {history}=props;
    const [[avt,build_nums,eval_nums,fans_nums,label,medal,nick,start_nums],set_user_data]=useState(['',0,0,0,'',[],'',0]);
    const [user_images,set_user_images]=useState([]);

    //全屏图片
    const [full_img_status,set_full_img_status]=useState(false);
    const [full_img_index,set_full_img_index]=useState(0);

    
    function set_show_img(index)
    {
        set_full_img_index(index);
        set_full_img_status(true);
    }
    
    //勋章
    function Xun(props)
    {
        return(
            <div className={"xun xun"+props.index}></div>
        )
    }  
    useEffect(()=>{
        let home_user_id=localStorage.getItem("home_user_id");
        let send_par={"api":"get_userhome","home_user_id":home_user_id}
        send_data(send_par,(e)=>{
            set_user_data([e.avt,e.build_nums,e.eval_nums,e.fans_nums,e.label,e.medal,e.nick,e.start_nums]);
            var image_list_data=[];
            e.images.forEach(element => {
                const cu_image_data=JSON.parse(element.sd_img);               
                for (const index in cu_image_data) {
                    const tm_data={
                        "url":cu_image_data[index],
                        "start":eval("element.start"+index),
                        "eval":eval("element.eval"+index),                      
                        "id":element.id,
                        "index":index
                    };     
                    image_list_data.push(tm_data);
                }                
            });
            set_user_images(image_list_data);
        })
    },[])
    //作品集列表  
    function ImageList()
      {
        function replacePngWithMini(filename) {
            return filename.replace('.png', '_mini.png');
        }
        return (
            <div className="image_list">

                {user_images.map((image,index)=>{
                    
                    return(
                        <>
                        <div>
                            <img key={index} onClick={()=>set_show_img(index)} src={replacePngWithMini(image.url)} />
                            <div className="start_panel">
                                <div className="start_nums no_repeat">{image.start}</div>
                                <div className="eval_nums no_repeat">{image.eval}</div>
                            </div>
                        </div>                        
                    </>
                    )
                })}
            </div>
        );
      }; 
      //背景图层
      function Back_layer(){
        return(
            <div className="back_layer">
                <div className="log_lay1"></div>
                <div className="log_lay2"></div>
                <div className="log_lay3"></div>
                <div className="log_lay4"></div>
            </div>
        )
    }
    
    return(
        <>
            
            <Back_layer />
             
            {full_img_status==true?<Show_img_list full_img_index={full_img_index} full_img_data={user_images} avt={avt} nick={nick}  set_full_img_status={set_full_img_status} />:''}
            <div className="user_home_page">                
                <div className="usr_background">
                    <div className="usr_box">
                          <img className="usr_back_img" src="https://ai.anyidata.com/images/20230720/20230720093652103353040.png" />
                          <div className="user_back_left"></div>
                          <img className="usr_avt" src={avt==null?Stable_Diffusion_avt:avt} />  
                          <div className="usr_info">
                                <div className="usr_lin">
                                    <div className="usr_foc">{fans_nums} 粉丝</div>
                                    <div className="usr_foc">{start_nums} 赞</div>
                                </div> 
                                <div className="usr_name">{nick}</div>                               
                                <div className="xun_box">
                                    <Xun index="1" />
                                    <Xun index="2" />
                                    <Xun index="3" />
                                    <Xun index="4" />
                                    <Xun index="5" />
                                </div>
                                <div className="usr_data">
                                • <span>{build_nums}+</span> 创作  
                                • <span>{eval_nums}K+</span> 评价  
                                </div>
                                <div className="usr_lin">
                                    <div className="usr_label">
                                        <span>摄影师</span>
                                        <span>艺术家</span>
                                        <span>人工智能</span>
                                        <span>创意</span>
                                    </div>
                                </div>
                          </div>
                    </div>
                </div>
                <div className="usr_ban"></div>
                <div className="usr_product">
                    <ImageList />  
                </div>
            </div>
            <div className="usr_top">
                    <span onClick={()=>history.push('/')}>&lt;返回</span>
            </div>
        </>
    )
}



export default User_home