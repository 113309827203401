import './App.css';
import { HashRouter,Route } from 'react-router-dom';
import Home_page from './home';
import User_home from "./user_home";



function App() {
  return (
    <HashRouter>
      <Route path='/' component={Home_page} exact />
      <Route path="/user_home" component={User_home} exact />
    </HashRouter>
  );

}

export default App;
