import md5 from 'js-md5';
import { saveAs } from "file-saver";
import { useEffect,memo, useRef} from 'react';
import { useState } from 'react';
import close_btn from './res/close-circle-fill.svg';
import Stable_Diffusion_avt from './res/ai.png';
import ReactToPrint from 'react-to-print';

const local_key='ff8d5ebda77e4f6bf675469d808b14f2ab3f6667';
async function send_data(send_par,callback)
{
    let user_key=localStorage.getItem("token");
    let uid=localStorage.getItem("uid");    
    if (uid>0){      
      send_par['uid']=uid;
      let sign=md5(JSON.stringify(send_par)+user_key+local_key);
      send_par['sign']=sign;
    }  
   
    send_par=JSON.stringify(send_par);
    const cu_url='https://ai.anyidata.com/main.php';
    await fetch(cu_url,{
            method:'POST',
            headers:{
                'content-type': 'application/json',
                'Accept-Language':'zh-CN'
            },
            body:send_par
        })
        .then(          
          res=>res.json()
          )
        .then(data=>{   
          if(data.status=='err')
          {
            localStorage.clear();
            window.location.reload();
            return;
          }   
          
          callback(data)
        })
        .finally(); 
        
};

function Model_set(props){
  return(
      <div className={[props.long_att==true?"model_set long_set":"model_set"]}>
          <div>{props.title}</div>
          <div>
              {props.value}
          </div>
      </div>
  )
} 

function Show_img_list(props)
{
  const [cu_image_index,set_cu_image_index]=useState(props.full_img_index);
  const [cu_image_data,set_cu_image_data]=useState(props.full_img_data[props.full_img_index]);
  const [img_info,set_img_info]=useState([]);
  const [eval_list,set_eval_list]=useState([]);
  const [eval_status,set_eval_status]=useState(false);  
  const [eval_molld,set_eval_mold]=useState(1);
  const [eval_recv_id,set_eval_recv_id]=useState(0);
  const [eval_recv_nick,set_eval_recv_nick]=useState('');

  const print_img=useRef(null);
  const print_panel=useRef(null);
  function Eval_prompt_panel()
  {
    return(
      <div>
      <div className="model_prompt">
          提示词：
          <div>
          {img_info.prompt}
          </div>
      </div>
      {img_info.negivate!=''?<div className="model_prompt">
          反向提示词：
          <div>
          {img_info.negivate}
          </div>
      </div>:''}
      <Model_set long_att={true} title="采样方法" value={img_info.sampling_method} />
      <Model_set long_att={true} title="Seed" value={img_info.seed} />
      <Model_set title="CFG权重" value={img_info.cfg} />                     
      <Model_set title="迭代步数" value={img_info.steps} />                 
      <Model_set title="Clip" value={img_info.clip} />
      </div>
    )
  }  
  function Eval_head()
  {
    return(
      <>
      <div className='eval_head'>
          <img className='eval_avt' src={props.avt==null?Stable_Diffusion_avt:props.avt} />
          <span className='eval_nick'>@{props.nick}</span>
          <span className='eval_time'>{img_info.dateline}</span> 
          <img className='close_img' onClick={()=>props.set_full_img_status(false)} src={close_btn} />
      </div>   
      <div className='split_panel'></div>
      </>
    )
  }
  function Eval_start()
  {
    function do_startimg()
    {
      let send_par={"api":"img_start","img_id":cu_image_data.id,"img_index":cu_image_data.index};
      send_data(send_par,(e)=>{
          if(e.status=='success')
          {
            load_data(cu_image_data.id,cu_image_data.index);
          }
      });
    }
    return(
       <div className='eval_start'>
          <div className='eval_spanel' onClick={()=>do_startimg()}>
            <div className='eval_heart'></div>
            <div className='eval_start_nums'>{img_info.start}</div>
          </div>
          <div className='eval_spanel' onClick={()=>set_eval_status(true)}>
            <div className='eval_eval' ></div>
            <div className='eval_start_nums' >{img_info.eval}</div>
          </div>
      </div>
    )
  }
  function handle_close_eval()
  {
            set_eval_mold(1);
            set_eval_recv_id(0);
            set_eval_recv_nick('');
  }
  
  function Eval_recv_box()
  {    
    const [eval_text,set_eval_text]=useState('');
    function handle_eval(e)
    {
      if(window.event.keyCode==13 && e.target.value.replace(/\s/g,"")=='')
      {
          e.preventDefault();
          return;
      }
  
      if(window.event.keyCode==13 && e.target.value.replace(/\s/g,"")!='')
      {
              let send_par={
                "api":"write_eval",
                "eval_molld":eval_molld,
                "eval_recv_id":eval_recv_id,
                "msg_id":cu_image_data.id,
                "img_index":cu_image_data.index,
                "text":eval_text,
                "rev_nick":eval_recv_nick
              };
              send_data(send_par,(e)=>{
                handle_close_eval();
                load_data(cu_image_data.id,cu_image_data.index);
              })
              set_eval_text('');
              e.preventDefault();   
      }
    }
    
    function Eval_recvmd_panel()
    {
      return(
        <div className='eval_recv_title'>
            <div className='eval_recv_left'>回复@{eval_recv_nick}</div>
            <div onClick={()=>{handle_close_eval()}} className='eval_recv_close'>关闭</div> 
        </div>
      )
    }
    return(
      <>
        {eval_molld==2?<Eval_recvmd_panel />:''}
        <div className='eval_recv_box'>
          <input className='eval_input' value={eval_text} onKeyDown={(e)=>handle_eval(e)} onChange={(e)=>set_eval_text(e.target.value)} placeholder='留下你的精彩评论吧' />
        </div>
      </>
    )
  }
  function Eval_eval_list()
  {

    function do_recv_id(id,nick)
    {
        set_eval_recv_id(id);
        set_eval_mold(2);
        set_eval_recv_nick(nick)
    }

    function do_start(recv_id)
    {
        let send_par={"api":"eval_start","id":recv_id};
        send_data(send_par,(e)=>{
          if(e.status=='success')
          {
            load_data(cu_image_data.id,cu_image_data.index);
          }
        })
    }

    function Load_more_eval(props)
    {
      const [more_list,set_more_list]=useState(null);
      function do_load_more_eval()
      {
        let send_par={"api":"load_more_eval","id":cu_image_data.id,"index":cu_image_data.index,"recv_id":props.recv_id};
        send_data(send_par,(e)=>{
          set_more_list(e.data);
        });
      }
      return(
        <div className='load_more_eval' onClick={()=>do_load_more_eval()}>
            —展开其余{props.rev_nums}评论
            {more_list!=null?<Show_eval_list eval_data={more_list} load_more={false}  />:''}
        </div>
      )
    }

    
    function Show_eval_list(props)
    {
      
      const cu_eval_list=props.eval_data;
      return(
        <div className='eval_mspanel'>
                {cu_eval_list!=null?cu_eval_list.map((item,index)=>{
                  return(
                    <div key={index} className='eval_list_panel'>
                      <div className='eval_list_head'>
                          <img className='eval_list_avt' src={item.avt==null?Stable_Diffusion_avt:item.avt} />
                          <span className='eval_list_nick'>@{item.nick}{props.load_more==false?<> <span>▶</span> {item.rev_nick}</>:''}</span>
                      </div>
                      <div className='eval_list_text'>
                          {item.text}
                      </div>
                      <div className='eval_list_text eval_location'>
                          {item.dateline+'·'+item.location}
                      </div>
                      <div className='eval_list_oth'>
                          <div onClick={()=>{do_recv_id(item.recv_id,item.nick)}}  className='eval_list_btn eval_list_recv'>回复</div>
                          <div onClick={()=>{do_start(item.id)}} className='eval_list_btn eval_list_start'>{item.start}</div>
                      </div>
                      {props.load_more==true?<>{item.rev_nums>0?<Load_more_eval recv_id={item.recv_id}  rev_nums={item.rev_nums} />:''}</>:''}
                  </div>
                  )
                }):''}  
              </div>
      )
    }
       
      return(
          <div className='eval_main_panel'>
            <div onClick={()=>{set_eval_status(false);handle_close_eval()}} className='close_eval_list'></div>            
            <Show_eval_list eval_data={eval_list} load_more={true} />
            <Eval_recv_box />
          </div>
      )
       
  }
  function load_data(id,index)
  {
    var send_par={"api":"get_imginfo","id":id,"index":index};
    send_data(send_par,(e)=>{
      set_img_info(e.data);
    }); 
    var send_par={"api":"get_eval_list","id":id,"index":index};
    send_data(send_par,(e)=>{
      set_eval_list(e.data);
    })
  }


  useEffect(()=>{
    load_data(cu_image_data.id,cu_image_data.index);  
  
  },[])

  function img_pre()
  {
      if(cu_image_index>0)
      {
        let cu_index=cu_image_index-1;
        let cu_data=props.full_img_data[cu_index];
        set_cu_image_index(cu_index);
        set_cu_image_data(cu_data);    
        load_data(cu_data.id,cu_data.index); 
      }
  }

  function img_next()
  {
    if(cu_image_index<2 && cu_image_index<props.full_img_data.length-1)
      {
        let cu_index=cu_image_index+1;
        let cu_data=props.full_img_data[cu_index];
        set_cu_image_index(cu_index);
        set_cu_image_data(cu_data);    
        load_data(cu_data.id,cu_data.index);
      }
  }

  return(
    <div className={props.margin_left!=true?'fullshow_panel':'fullshow_panel full_margin_left'}>
        <div className='fullimg_panel'>
          <div className='full_parcent_panel'><img ref={print_img} className='fullimg_panel_img' src={cu_image_data.url} /></div>
          <div className='eval_panel'>              
              <Eval_head />    
              {eval_status==false?<>
                <Eval_start />
                <Eval_prompt_panel />                
                <div className='eval_split'></div>
              </>:<Eval_eval_list /> } 
          </div>
          <div className='change_panel'>
                <div onClick={()=>img_pre()}>上一张</div>   
                <ReactToPrint ref={print_panel}
                    trigger={() =><div>打印</div>}
                    content={() => print_img.current}
                />             
                <div onClick={()=>img_next()}>下一张</div>
                
          </div>
        </div>
        
    </div> 
  )
}



function download(img_url,img_name)
{   
   saveAs(img_url,img_name);
};

export {send_data,download,Show_img_list,Model_set};

