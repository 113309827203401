import { useState,useRef } from "react";
import {send_data} from "./lib"

function Login()
{
    const [login_status,set_login_status]=useState(false);
    const mobile_ref=useRef(null);
    const rand_ref=useRef(null);
 
    const token=localStorage.getItem("token");

    function logout()
    {
        localStorage.clear();
        window.location.reload();
    }
    function tocenter()
    {
        localStorage.setItem("cu_page","center");
        window.location.reload();
    }
    function handle_login(){
        const mobile=mobile_ref.current.value;
        const rand=rand_ref.current.value;

        if(mobile.length!=11 || /^\d+$/.test(mobile)==false)
        {
            alert('请输入正确的手机号');
            return false;
        }
        if(rand.length!=4 || /^\d+$/.test(rand)==false)
        {
            alert('请输入正确的短信验证码');
            return false;
        }

        //登录
        let send_par={
            "api":"mobile_login",
            "mobile":mobile,
            "rand":rand
        }
        send_data(send_par,(e)=>{                         
            if(e.status=='success')
            {
                localStorage.setItem("mobile",mobile);
                localStorage.setItem("token",e.token);
                localStorage.setItem("uid",e.uid);
                window.location.reload();
            }
            else{
                alert(e.status);
            }

        });
    }
    
    function Start_build()
    {
        function do_startbuild()
        {
            if(token!=null)
            {
                tocenter();
                return;
            }
            set_login_status(true)
        }
        return(
            <div className="start_build" onClick={()=>do_startbuild()}></div>
        )
    }
    function Back_layer(){
        return(
            <div className="back_layer">
                <div className="log_lay1"></div>
                <div className="log_lay2"></div>
                <div className="log_lay3"></div>
                <div className="log_lay4"></div>
            </div>
        )
    }
    function Contex_layer()
    {
        return(
            <div className="con_layer">
                <div className="log_head">
                    <div className="log_img"></div>                    
                    <div className="btn_panel">
                        {token==null?<div className="log_btn" onClick={()=>set_login_status(true)}>登录</div>:<></>}
                        {token!=null?<div className="log_btn" onClick={()=>tocenter()}>个人中心</div>:<></>}
                        {token!=null?<div className="log_btn" onClick={()=>logout()}>退出</div>:<></>}
                    </div>
                </div>
                <div className="main_ad"></div>
                <div className="on_word">一句描述词就能打开您天马行空的艺术画作</div>
                <Start_build />
                <div className="home_img_list">
                    <img src="https://ai.anyidata.com/images/20230624/20230624031522127439176.png" />
                    <img src="https://ai.anyidata.com/checkpoing_img/revAnimated_v122-3.png" />
                    <img src="https://ai.anyidata.com/checkpoing_img/revAnimated_v122-2.jpeg" />
                    <img src="https://ai.anyidata.com/checkpoing_img/majicmixRealistic_v6-0.jpeg" />
                    <img src="https://ai.anyidata.com/checkpoing_img/darkSushiMixMix_225D2.jpeg" />            
                    <img src="https://ai.anyidata.com/checkpoing_img/revAnimated_v122-0.jpeg" />           
                    <img src="https://ai.anyidata.com/checkpoing_img/realisticVisionV20_v20NoVAE-0.jpeg" />
                </div>
                <div className="build_img">
                    <div className="build_img_left">
                        <div className="build_title"></div>
                        <span>基于Stable Diffusion，在几秒钟内便可以把你的想象力呈现多种风格的艺术作品。</span>
                        <span>文本到图像的扩散模型在给定任何文本描述的情况下产生高质量的现实主义艺术。只需输入一个文本提示符，我们的生成器将您的文字转换成令人难以置信的图像。</span>
                        <Start_build />
                   </div>
                    <div className="build_img_right">
                        <img src="https://ai.anyidata.com/checkpoing_img/majicmixRealistic_v4-1.jpg" />
                    </div>
                </div>
                <div className="build_img">
                    <div className="build_img_left2">
                        <div className="md1"></div>
                        <div className="md2"></div>
                        <div className="md3"></div>
                    </div>
                    <div className="build_img_right2">                        
                        <div className="aistock_title"></div>
                        <span>丰富的AI模型库开箱即用，每一个模型都有相应的参考示例，为您的创作灵感带来独特和创意。</span>
                        <span>同时您也可以自行上传您喜欢的模型</span>
                        <Start_build />
                    </div>
                </div>
                <div className="page_bottom">
                    <div className="bottom_contex">
                        <div className="log_img_white bottom_log"></div>
                    </div>
                </div>
            </div>
        )
    }
    function Login_btn(){
        return(
            <>
                <div className="login_btn" onClick={()=>handle_login()}>
                    立即登录
                </div>
            </>
            
        )
    }    
    //发送验证码
    function exe_ver_btn(ver_btn_str,set_ver_btn_str,mobile)
    {
    
    if(mobile=='' || mobile==null || mobile.length!=11 || /^\d+$/.test(mobile)==false)
    {
        alert('请输入正确的手机号');
        return false;
    }
    if(ver_btn_str!='获取')return;
    set_ver_btn_str(60);
    ver_btn_str=60;
    let timerID=setInterval(()=>{
        if(ver_btn_str>1)
        ver_btn_str--;
        else
        {
        ver_btn_str='获取';
        clearInterval(timerID);
        }
        set_ver_btn_str(ver_btn_str);
    },1000)

    //发送验证码
        let send_par={
            "api":"send_message",
            "mobile":mobile
        }
        send_data(send_par,(e)=>{                         
            if(e.status=='success')
            {
              
            }
            else{
                alert(e.status);
            }

        });

    }
    function Log_layer(){
        const [mobile,set_mobile]=useState("");
        const [rand,set_rand]=useState("");
        const [ver_btn_str,set_ver_btn_str]=useState('获取');
        return(
            <div className="log_layer">
                <div className="log_box">
                    <div className="log_close">
                        <div className="close_btn" onClick={()=>set_login_status(false)}></div>
                    </div>
                    <div className="log_title">
                        手机号登录
                    </div>
                    <div className="input_box">
                          
                         <div className="input_box_inputdiv">
                            <div className="mobile_svg"></div>
                            <input ref={mobile_ref} className="mobile_input" value={mobile} onChange={(e)=>set_mobile(e.target.value)} maxLength="11" placeholder="手机号码" />
                         </div>
                         <div className="input_box_inputdiv">
                            <div className="ran_svg"></div>
                            <input ref={rand_ref} className="mobile_input" value={rand} maxLength="6" onChange={(e)=>set_rand(e.target.value)} placeholder="验证码" />
                            <div className="get_rand" onClick={()=>exe_ver_btn(ver_btn_str,set_ver_btn_str,mobile)}>{ver_btn_str}</div>                            
                        </div> 
                        <Login_btn />
                    </div>
                </div>
            </div>
        )
    }
    return(
        <>
            <Back_layer />
            <Contex_layer />
            {login_status==true?<Log_layer />:''}
        </>

    )
}


export default Login