import { useState,useRef, useEffect} from "react";
import Login from "./login";
import Left_side from "./left";
import Right_side from "./right";


function Home_contex(props){  
    const history=props.history;
    //消息页面索引
    const [cu_message_page,set_cu_message_page]=useState('创作大厅');  
    const scroll_box=useRef(null);
    const input_box=useRef(null);
    const up_img=useRef(null);    
    
    //主菜单索引
    const [main_menu_index,set_main_menu_index]=useState("AI绘图");
    //子菜单索引
    const [cu_sec_mem_index,set_cu_sec_mem_index]=useState("创作大厅");
        
    return(
        <div className="home_contex">            
            <Left_side 
                 history={history}
                main_menu_index={main_menu_index} set_main_menu_index={set_main_menu_index} 
                cu_sec_mem_index={cu_sec_mem_index} set_cu_sec_mem_index={set_cu_sec_mem_index} 
                set_cu_message_page={set_cu_message_page} 
            />         
            <Right_side 
                history={history}
                main_menu_index={main_menu_index} set_main_menu_index={set_main_menu_index} 
                cu_sec_mem_index={cu_sec_mem_index} set_cu_sec_mem_index={set_cu_sec_mem_index} 
                cu_message_page={cu_message_page} set_cu_message_page={set_cu_message_page}
                scroll_box={scroll_box}
                input_box={input_box}
                up_img={up_img}            
            />
        </div>
    )
}


function Home_page(props){ 
    
        const {history}=props;
        const mobile=localStorage.getItem("mobile");
        const uid=localStorage.getItem("uid");
        const token=localStorage.getItem("token");
        const cu_page=localStorage.getItem("cu_page");
        let out_data='';
        if(cu_page=="login" || cu_page==null || cu_page=='')
        {
            
            out_data=<div className="log_page"><Login /></div>
        }
        else
        {
            out_data=<div className="home_page"><Home_contex history={history} /></div>
        }

    return(
        <>
            {out_data}

            {/* <div style={{"color":"green","display":"flex","alignItems":"center","justifyContent":"center","height":"60vh"}}>
                 <h3>正在升级中，请稍后再试...</h3>
            </div> */}
        </>
        
    )
   
}


export default Home_page;